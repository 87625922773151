<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const isMobile = useIsMobile()

const router = useRouter()

const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = 2096
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const isMobileOrTablet = ref(true)

onMounted(() => {
  isMobileOrTablet.value = window.innerWidth < 1024
})

const destination = computed(() => {
  let domain = ''

  let path =
    isWorkingHours.value && isMobile.value
      ? '/additional-social-security-call-now'
      : '/ads'

  const params = new URLSearchParams()

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Your Social Security ss1',
  category: 'LP',
  product: 'medicare',
})

const shrinkClass = ref('')
const onTypewriterDone = () => {
  shrinkClass.value = ' !w-[190px]'
}
</script>
<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'bridge' : '2025'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div
      class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
    >
      <span
        class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
      >
        Ad
      </span>

      <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
        <!-- <div class="flex justify-end">
          <AllowanceCard
            :target-value="stateConfig?.minOtcLtd"
            :class="['rotate-6 custom-transition w-[310px]', shrinkClass]"
            @done="onTypewriterDone"
          />
        </div> -->
        <div
          class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
        >
          GET YOUR
          <span class="text-6xl md:text-7xl text-yellow">
            {{ USDollarFormatter(0).format(output) }}/yr+
          </span>
          Back On Social Security Yet?
        </div>

        <div
          class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
        >
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'no',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              No
            </span>
          </Button>
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'yes',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              Yes
            </span>
          </Button>
        </div>

        <img
          src="../../assets/retirement-balance.png"
          alt="Two retirement balance, the left one with 'Before' and a list of $0 deposits and the second one with 'After' with several deposits of $174"
        />
      </div>
    </div>
    <div class="bg-black text-white">
      <div class="container space-y-2 py-2">
        <div class="text-sm text-center"> Turning 65? </div>
        <div class="text-center text-gray-400"> MULTI-PLAN_HLDAD1224324_M </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}

.custom-transition {
  transition: width 0.5s;
}
.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}
@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
