<script setup lang="ts">
import bhLogo from '~/assets/logo-bh.png'
import m10Logo from '~/assets/logo-m10.png'

useHead({
  title: `Shop Medicare Plans Online - ${brand.name}`,
})

analytics.page({
  name: 'MediaAlpha Ads 1',
  category: 'LP',
  product: 'medicare',
})

const isMounted = useMounted()

const isMobile = useIsMobile()

onMounted(() => {
  if (isMobile.value) {
    enterExperiment('houseAds')
  }
})

const domain = brand.id === 'm10' ? 'benefit-helpline.com' : 'medicare10.com'
const logo = brand.id === 'm10' ? bhLogo : m10Logo

const ads = [
  {
    url: `https://${domain}/go-4-benefits/call`,
    logo,
    star: true,
    verified: true,
    title: '2025 Medicare Advantage Plans',
    items: [
      'Find a plan that fits your needs',
      'Additional benefits available for those who qualify',
      'Check your zip code to get started',
      'Annual Enrollment Period ends 12/7',
    ],
    button: 'Shop Online',
  },
]

const route = useRoute()

const blackVersion = computed(() => {
  return route.query.black === 'true'
})
</script>

<template>
  <Layout
    :hide-header="isMobile || !isWorkingHours"
    :header="blackVersion ? 'simpleBlack' : undefined"
    footer="2024"
    :class="blackVersion ? 'bg-black text-white' : ''"
  >
    <template #banner>
      <Banner> New to Medicare? On Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6 px-4">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Click 2 or More Options
      </div>
      <div class="max-w-3xl mx-auto space-y-6">
        <MediaAlphaAd :user="{}" />
      </div>
      <HouseAds
        v-if="exp?.houseAds?.bottom && isMounted && isMobile"
        :ads="ads"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10", "med10", "bridge"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "robots": "noindex"
  }
}
</route>
